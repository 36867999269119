import logo from "../../assets/kmc-logo-round.png"
import React from "react";
import classNames from "classnames";

export default class Reg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phone: "",
            nameError: false,
            phoneError: false,
            agreed:false,
            checkError:false,
            phoneErrorMsg:"",
        }
    }

    render() {
        const {name, phone, nameError, phoneError,checkError,phoneErrorMsg} = this.state;
        return (
            <div className="container reg">
                <div className="logo">
                    <img src={logo}/>
                </div>
                <div className="title">
                    <p>注册参加</p>
                    <p className="blue">金秋十月 深藏Blue</p>
                </div>
                <div className="form column">
                    <div className={classNames("input-group", {error: nameError})}>
                        <input value={name} placeholder="请输入您的姓名" onChange={(e) => this.setState({name: e.target.value,nameError:false})}/>
                        <p className="error-msg">姓名不能为空</p>
                    </div>
                    <div className={classNames("input-group", {error: phoneError})}>
                        <input value={phone} placeholder="请输入您的手机号码" onChange={(e) => this.setState({phone: e.target.value,phoneError:false})}/>
                        <p className="error-msg">{phoneErrorMsg}</p>
                    </div>
                    <button className="primary" onClick={() => this.onSubmit()}>下一步：拍摄或上传照片</button>
                </div>
                <div className={classNames("check","input-group", {error: checkError})}>
                    <div className="check-group">
                    <input type="checkbox" id="chk" onChange={(e)=>{this.setState({agreed:e.target.checked,checkError:false})}} />
                    <label htmlFor="chk">我承诺我上的照片不存在侵权行为，任何侵权行为的后果由我承担；同时，对于本次活动中上传的照片，我授权科尔摩根无限期且仅以宣传为目的使用的权利。</label>
                    </div>
                    <p className="error-msg">需要同意此选项</p>
                </div>
            </div>
        )
    }

    onSubmit() {
        const {name, phone, agreed} = this.state;
        let pass = true;
        if (!name) {
            this.setState({nameError: true});
            pass = false;
        } else {
            this.setState({nameError: false});
            pass &=true;
        }

        if (!phone) {
            this.setState({phoneError: true,phoneErrorMsg:'手机号码不能为空'});
            pass = false;
        } else if(/^((\+|00)?86(-)?)?1[0-9]{10}$/.test(phone)){
            this.setState({phoneError:false});
            pass&=true;
        }else{
            this.setState({phoneError: true,phoneErrorMsg:'手机号码格式不正确。仅支持中国大陆手机号码'});
            pass = false;
        }

        if(!agreed){
            this.setState({checkError:true});
            pass = false;
        }else{
            this.setState({checkError:false});
            pass &=true;
        }
        if (pass) {
            if (this.props.onSubmit) {
                this.props.onSubmit({name: name, phone: phone});
            }
        }
    }

}