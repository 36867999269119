import Upload from "./upload";
import React from "react";
import "./style.scss"
import Reg from "./reg";
import service from "../../util/request"
import logo from "../../assets/kmc-logo-round.png"
import Toast from "../../util/Message/Toast";
import {parseQuery} from "../../util/utils";
import qrCode from "../../assets/mmexport1665760513601.jpg"

export default class EventBlueScenery extends React.Component{
    constructor(props){
        super(props);
        this.state={
            name:"",
            phone:"",
            step:1,
        }

    }
    componentDidMount(){
        document.title="金秋十月 深藏Blue";
    }

    onReg(e){
        this.setState({
            name:e.name,
            phone:e.phone,
            step:2,
        })
    }

    onUploaded(e){
        const{name,phone} = this.state;
        const queryParams = parseQuery(window.location.search);
        var params={
            paticipantInfo:JSON.stringify({name:name,phone:phone}),
            event:"2022-10-1-金秋十月深藏Blue",
            detail:JSON.stringify({name:name,phone:phone,imageUrl:e.url}),
        };
        if(queryParams.userid){
            params.userid= parseInt(queryParams.userid);
        }

        service.POST("eiRecord/createEventInteractRecord",params).then((res)=>{
            this.setState({
                step:3
            })
        }).catch((e)=>{
            Toast.show("提交失败")
        })
    }

    render(){
        const{name,phone,step} = this.state;
        /*return(
            <div className="page">
                {step===1 &&(
                    <Reg
                        onSubmit={(e)=>this.onReg(e)}
                    />
                )}
                {step===2 &&(
                    <Upload
                        name={name}
                        phone={phone}
                        onSuccess={(e)=>this.onUploaded(e)}
                    />
                )}
                {step===3 && (
                    <div className="container reg">
                        <div className="logo">
                            <img src={logo}/>
                        </div>
                        <div className="title">
                            <p className="blue">上传成功！</p>
                            <p>感谢您的参与</p>
                        </div>
                    </div>
                )}
            </div>

        )*/
        return(
            <div className="page end">
                <div className="container">
                    <p>本次拍照上传活动已经结束，<br/>请您关注“科学家俱乐部”公众号参与投票！</p>
                    <img src={qrCode} />
                </div>
            </div>
        )
    }
}