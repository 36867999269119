import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import "./style.scss"
const Modal = (props) => {
    const { children,container } = props;

    return ReactDOM.createPortal(
        // 这里可以是dom元素,也可以是react组件,还可以是react节点
        <div className="toast">{children}</div>,
        // 将传入的元素/组件/节点,挂载到container下面
        container
    )
}

const Message = (props) => {
    const { visible, onCancel, container, onFinish, msg, ...rest } = props;
    const [_container, setContainer] = useState(null);

    useEffect(
        () => {
            if (visible) return;
            if (typeof onFinish === 'function') {
                onFinish();
            }
        },
        [visible]
    );

    useEffect(
        () => {
            setContainer(container || document.body)
        },
        [container]
    );

    useEffect(
        () => {
            const cb = (e) => {
                const ele = e.target;
                if (ele && ele.className !== "overlay") {
                    onClose();
                    onClear();
                }
            }

            document.addEventListener('click', cb);
            return () => {
                document.removeEventListener('click', cb);
            }
        },
        []
    );

    useEffect(
        () => {
            if (!visible) {
                onClear();
            }
        },
        [visible, onCancel, onFinish]
    )

    function onClose() {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    }

    function onClear() {
    }

    if (!_container) return null;
    return (
        <Modal
            className={"overlay"}
            visible={visible}
            onClick={(e) => { e.stopImmediatePropagation() }}
            container={_container}
            {...rest}
        >
            <div className="message">{msg}</div>
        </Modal>
    )
};

let msgBox = null;

// type弹窗类型,可以根据不同类型,渲染不同类型弹窗的样式
const MessageAPI = (msg, callBack) => {
    onCancel();

    msgBox = document.createElement('div');
    document.body.appendChild(msgBox);

    function onCancel() {
        if (!!msgBox) {
            ReactDOM.unmountComponentAtNode(msgBox);
        }
    }

    console.log(msgBox)

    ReactDOM.render(
        <Message
            visible={true}
            onCancel={onCancel}
            onFinish={() => !!callBack && callBack()}
            msg={msg}
        />,
        msgBox
    );
};

const hideMessage=()=>{
    if (!!msgBox) {
        ReactDOM.unmountComponentAtNode(msgBox);
    }
};

const Loading={
    show:(msg) => MessageAPI(msg?msg:"Loading...",null),
    hide:()=>hideMessage(),
};

export default Loading;
