
const config = {
    API_BASE:"https://kollmorgen-service.shangkentech.com/",
    // API_BASE:"http://10.0.0.2:8888/",
    //DEBUG:true,
    API_USER:"apiUser",
    // API_KEY:"123456",
    API_KEY:"NKXXyBqsSYQduGjKtJ2CZRjLScZ7Wb3Q",
    DIRECTUS_BASE:'https://directus-kollmorgen.shangkentech.com/',
    IMAGE_BASE: "https://kollmorgen-cdn.shangkentech.com/kollmorgen-miniprogram/",
    SEARCH_BASE:'https://meilisearch-kollmorgen.shangkentech.com/indexes/',
    SEARCH_TOKEN:'EYsnHz3a3thDZ4r9ky*q9gq%dyfp!VLP',
};

export default config;