import service from "./request"
import Loading from "./Message/Loading"


const OSS = require("ali-oss");


let client;

const init = () => {
    return new Promise((resolve, reject) => {
        service.GET("upload/getToken",null,{
            doNotShowLoading:true
        }).then((res) => {
            console.log(res);
            const token = res.Credentials;
            client = new OSS({
                region: 'oss-cn-shanghai',
                accessKeyId: token.AccessKeyId,
                accessKeySecret: token.AccessKeySecret,
                stsToken: token.SecurityToken,
                // 填写Bucket名称，例如examplebucket。
                bucket: "kollmorgen-events",
                // 刷新临时访问凭证。
                refreshSTSToken: async () => {
                    const resp = await service.GET("upload/getToken");
                    const refreshToken = resp.Credentials;
                    return {
                        accessKeyId: refreshToken.AccessKeyId,
                        accessKeySecret: refreshToken.AccessKeySecret,
                        stsToken: refreshToken.SecurityToken,
                    };
                },

            });
            resolve(client);
        }).catch((e) => {reject(e)});
    })
};

const headers = {
    // 指定Object的存储类型。
    'x-oss-storage-class': 'Standard',
    // 指定Object的访问权限。
    'x-oss-object-acl': 'public-read',
    // 设置Object的标签，可同时设置多个标签。
    // 'x-oss-tagging': 'Tag1=1&Tag2=2',
    // 指定PutObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
    'x-oss-forbid-overwrite': 'true',
};


const uploader = {
    upload: (file, filename, prefix) => {
        Loading.show("上传中...");
        let path = prefix+"/"+filename;
        return new Promise((resolve, reject) => {
            let p;
            if (!client) {
                p = init();
            } else {
                p = Promise.resolve(client)
            }

            p.then((client) => {
                var fr = new FileReader();
                fr.readAsArrayBuffer(file);
                fr.addEventListener("load",(e)=>{
                    var buf = e.target.result;
                    client.put(path, OSS.Buffer.from(buf), {headers}).then(
                        (res) => {
                            Loading.hide();
                            resolve(res)
                        }
                    ).catch((e) => {reject(e)})
                })
            });
        });
    }
}

export default uploader;