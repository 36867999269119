import './App.scss';

import React from "react";
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom'

import PageIndex from "./page/index"
import Test from "./page/test"
import EventBlueScenery from "./events/20221001";


function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route exact path="/" element={<PageIndex/>}/>
                    <Route exact path="/test" element={<Test/>}/>
                    <Route exact path="/events/20221001" element={<EventBlueScenery/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
