import React from "react";
import Toast from '../util/Message/Toast'
import service from "../util/request"

export default class Test extends React.Component{
    componentDidMount(){
        console.log(this.props)
    }
    test(){
        service.GET("upload/getToken").then((res)=>{
            console.log(res)
        })
    }
    render(){
        return (
        <div>
        </div>
        )
    }
}