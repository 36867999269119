import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import "./style.scss"
const Modal = (props) => {
    const { children,container } = props;

    return ReactDOM.createPortal(
        // 这里可以是dom元素,也可以是react组件,还可以是react节点
        <div className="toast">{children}</div>,
        // 将传入的元素/组件/节点,挂载到container下面
        container
    )
}

const Message = (props) => {
    const { visible, onCancel, container, onFinish, msg,delay, ...rest } = props;
    const [_container, setContainer] = useState(null);
    const timer = useRef(null);

    useEffect(
        () => {
            if (visible) return;
            if (typeof onFinish === 'function') {
                onFinish();
            }
        },
        [visible]
    );

    useEffect(
        () => {
            setContainer(container || document.body)
        },
        [container]
    );

    useEffect(
        () => {
            const cb = (e) => {
                const ele = e.target;
                if (ele && ele.className !== "overlay") {
                    onClose();
                    onClear();
                }
            }

            document.addEventListener('click', cb);
            return () => {
                document.removeEventListener('click', cb);
            }
        },
        []
    );

    useEffect(
        () => {
            if (!!visible) {
                if (!!timer.current) clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                    onClose();
                    onClear();
                }, delay)
            } else {
                onClear();
            }
        },
        [visible, onCancel, onFinish]
    )

    function onClose() {
        if (typeof onCancel === 'function') {
            onCancel();
        }
    }

    function onClear() {
        clearTimeout(timer.current);
        timer.current = null;
    }

    console.log(_container);
    if (!_container) return null;
    return (
        <Modal
            className={"overlay"}
            visible={visible}
            onClick={(e) => { e.stopImmediatePropagation() }}
            container={_container}
            {...rest}
        >
            <div className="message">{msg}</div>
        </Modal>
    )
};

let msgBox = null;

// type弹窗类型,可以根据不同类型,渲染不同类型弹窗的样式
const MessageAPI = (type, msg, callBack,delay) => {
    onCancel();
    if(delay==null) delay = 2000;

    msgBox = document.createElement('div');
    document.body.appendChild(msgBox);

    function onCancel() {
        if (!!msgBox) {
            ReactDOM.unmountComponentAtNode(msgBox);
        }
    }

    console.log(msgBox);

    ReactDOM.render(
        <Message
            visible={true}
            onCancel={onCancel}
            onFinish={() => !!callBack && callBack()}
            msg={msg}
            delay={delay}
        />,
        msgBox
    );
};

const Toast ={
    show:(msg,callback,delay) => MessageAPI('message',msg,callback,delay),
    success:(msg,callback,delay) => MessageAPI('success',msg,callback,delay),
    error:(msg,callback,delay) => MessageAPI('error',msg,callback,delay),
};

export default Toast;
