import axios from 'axios'; // 引入axios
import axiosRetry from 'axios-retry';
import cookie from "react-cookies"

import CONFIG from '../config';
import Loading from './Message/Loading'
import Toast from "./Message/Toast";

function saveCookie(key, value) {
    cookie.save(key, value)
}

function loadCookie(key) {
    return cookie.load(key)
}


axios.defaults.headers['Access-Control-Allow-Origin'] = '*';

// axiosRetry(axios, {retries: 3});

const service = axios.create({
    baseURL: CONFIG.API_BASE,
    timeout: 99999
});


let acitveAxios = 0
let timer = 0;
const showLoading = () => {
    acitveAxios++;
    if (timer > 0) {
        clearTimeout(timer)
    }
    timer = setTimeout(() => {
        if (acitveAxios > 0) {
            Loading.show('加载中');
        }
    }, 400);
};

const closeLoading = () => {
    acitveAxios--;
    if (acitveAxios <= 0) {
        clearTimeout(timer);
        Loading.hide()
    }
};
//http request 拦截器
service.interceptors.request.use(
    async config => {
        if (!config.doNotShowLoading) {
            showLoading()
        }
        let token = loadCookie('token');
        let userid = loadCookie('apiUserId');
        if (config.url !== "/base/login" && config.url !== "base/login" && (!token || !userid)) {
            await init();
            token = loadCookie('token');
            userid = loadCookie('apiUserId');
        }
        config.data = JSON.stringify(config.data);
        config.headers = {
            'Content-Type': 'application/json',
            'x-token': token,
            'x-user-id': userid
        };
        return config;
    },
    error => {
        closeLoading();
        return error;
    }
);


//http response 拦截器
service.interceptors.response.use(
    response => {
        closeLoading();

        if (response.headers["new-token"]) {
            saveCookie('token', response.headers["new-token"])
        }
        if (response.data.code == 0 || response.headers.success === "true") {
            return response.data
        } else {
            // Message({
            //     showClose: true,
            //     message: response.data.msg || decodeURI(response.headers.msg),
            //     type: response.headers.msgtype||'error',
            // })
            if (response.data.data && response.data.data.reload) {
                // store.commit('user/LoginOut')
            }
            return response.data.msg ? response.data : response
        }
    },
    error => {
        closeLoading()
        // Message({
        //     showClose: true,
        //     message: error,
        //     type: 'error'
        // })
        return error
    }
);

const init = () => {
    return new Promise((resolve, reject) => {
        console.log("init");
        service.post("/base/login", {
            username: CONFIG.API_USER,
            password: CONFIG.API_KEY,
            captcha: "000000",
            captchaId: "00000000",
        }).then((resp) => {
            console.log("resp", resp);
            if (resp.code == 0) {
                saveCookie('token', resp.data.token);
                saveCookie('apiUserId', resp.data.user.ID);
                resolve(resp)
            }
        }).catch(e => reject(e))
    })
};


export default {
    service: service,
    GET: (url, params = null, config = null) => {
        let _config = config == null ? {} : config;
        let _params = params == null ? {} : params;
        _config.params = _params;
        return new Promise((resolve, reject) => {
            service.get(url, _config).then((resp) => {
                if (resp.code == 0) {
                    resolve(resp.data);
                } else {
                    reject(resp)
                }
            }).catch((err) => {
                Toast.error("网络错误");
                reject(err)
            });
        })
    },
    GET_RAW: (url, params = null, config = null) => {
        let _config = config == null ? {} : config;
        let _params = params == null ? {} : params;
        _config.params = _params;
        return new Promise((resolve, reject) => {
            service.get(url, _config).then((resp) => {
                resolve(resp)
            }).catch((err) => {
                Toast.error("网络错误");
                reject(err)
            });
        })
    },
    POST: (url, data = null, config = null) => {
        let _data = data == null ? {} : data;
        return new Promise((resolve, reject) => {
            service.post(url, _data, config).then((resp) => {
                if (resp.code == 0) {
                    resolve(resp.data);
                } else {
                    reject(resp)
                }
            }).catch((err) => {
                Toast.error("网络错误");
                reject(err)
            });
        })
    },
    POST_RAW: (url, data = null, config = null) => {
        let _data = data == null ? {} : data;
        return new Promise((resolve, reject) => {
            service.post(url, _data, config).then((resp) => {
                resolve(resp);
            }).catch((err) => {
                Toast.error("网络错误");
                reject(err)
            });
        })
    },
    DELETE: (url, data = null, config = null) => {
        let _config = config == null ? {} : config;
        let _data = data == null ? {} : data;
        _config.data = _data;
        return new Promise((resolve, reject) => {
            service.delete(url, _config).then((resp) => {
                if (resp.code == 0) {
                    resolve(resp);
                } else {
                    reject(resp)
                }
            }).catch((err) => {
                Toast.error("网络错误");
                reject(err)
            });
        })
    },
    PUT: (url, data = null, config = null) => {
        let _data = data == null ? {} : data;
        return new Promise((resolve, reject) => {
            service.put(url, _data, config).then((resp) => {
                if (resp.code == 0) {
                    resolve(resp);
                } else {
                    reject(resp)
                }
            }).catch((err) => {
                Toast.error("网络错误");
                reject(err)
            });
        })
    },
}
export {init}