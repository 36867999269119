import React from "react";
import uploader from "../../util/upload";
import md5 from "js-md5"

import logo from "../../assets/kollmorgenlogo.svg"
import Toast from "../../util/Message/Toast";

function getExt(filename) {
    if(typeof filename === 'string'){
        var arr = filename.split(".");
        if(arr.length>=2){
            return arr[arr.length-1];
        }else{
            return ""
        }
    }else{
        return ""
    }


}
export default class Upload extends React.Component{
    constructor(props){
        super(props);
        this.state={
            image:null,
            imageFile:null,
        }
    }

    chooseImage(e){
        console.log(e)
        const file = e.target.files[0];
        if(file){
            this.setState({
                image:URL.createObjectURL(file),
                imageFile:file
            })
        }
    }
    upload(){
        const{imageFile} = this.state;
        const{name,phone} = this.props;
        let filename = name+"-"+phone+"-"+md5(imageFile.name) + "_" + Date.now()+ "." + getExt(imageFile.name)
        uploader.upload(imageFile,filename,"2022-10-1-金秋十月深藏Blue").then((res)=>{
            if(this.props.onSuccess){
                this.props.onSuccess(res);
            }
        }).catch((e)=>{
            Toast.show("上传失败")
        })

    }

    render(){
        const {image} = this.state;
        return(
            <div className="container">
                {image ? (
                        <div className="preview column">
                            <img className="logo" src={logo} />
                            <img className="photo" src={image}/>
                            <div className="button-group column">
                            <button className="outline" onClick={()=>this.setState({image:null})}>

                                更换照片</button>
                            <button className="primary" onClick={()=>this.upload()}>提交</button>
                            </div>
                        </div>
                    ) : (
                        <div className="chooseImage column">
                            <label htmlFor="cam" className="button upload-btn">

                                <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M119.5 60C119.5 92.8609 92.8609 119.5 60 119.5C27.1391 119.5 0.5 92.8609 0.5 60C0.5 27.1391 27.1391 0.5 60 0.5C92.8609 0.5 119.5 27.1391 119.5 60Z" stroke="#1F427D"/>
                                    <path d="M72.6778 59.8999C72.6778 66.8629 67.0332 72.5075 60.0701 72.5075C53.1071 72.5075 47.4625 66.8629 47.4625 59.8999C47.4625 52.9368 53.1071 47.2922 60.0701 47.2922C67.0332 47.2922 72.6778 52.9368 72.6778 59.8999Z" fill="#1F427D"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1001 38.8901C20.1001 36.9568 21.6568 35.4004 23.5901 35.4004H96.3701C98.3035 35.4004 99.8599 36.9568 99.8599 38.8901V81.1796C99.8599 83.113 98.3035 84.6694 96.3701 84.6694H23.5901C21.6568 84.6694 20.1001 83.113 20.1001 81.1796V38.8901ZM93.4208 38.8876H81.4391C80.0026 38.8876 78.8461 40.0441 78.8461 41.4806C78.8461 42.9172 80.0026 44.074 81.4391 44.074H93.4208C94.8574 44.074 96.0139 42.9172 96.0139 41.4806C96.0139 40.0441 94.8574 38.8876 93.4208 38.8876ZM74.3579 45.611C70.5681 41.8213 65.4281 39.6922 60.0686 39.6922C54.7092 39.6922 49.5692 41.8213 45.7794 45.611C41.9897 49.4008 39.8606 54.5408 39.8606 59.9003C39.8606 65.2597 41.9897 70.3997 45.7794 74.1895C49.5692 77.9792 54.7092 80.1082 60.0686 80.1082C65.4281 80.1082 70.5681 77.9792 74.3579 74.1895C78.1476 70.3997 80.2766 65.2597 80.2766 59.9003C80.2766 54.5408 78.1476 49.4008 74.3579 45.611Z" fill="#1F427D"/>
                                </svg>
                                <span>拍摄照片</span></label>
                            <input id="cam" type="file" accept="image/*" capture="camera" style={{display: "none"}} onChange={(e)=>this.chooseImage(e)}/>
                            <label htmlFor="photo" className="button upload-btn">
                                <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M119.5 60C119.5 92.8609 92.8609 119.5 60 119.5C27.1391 119.5 0.5 92.8609 0.5 60C0.5 27.1391 27.1391 0.5 60 0.5C92.8609 0.5 119.5 27.1391 119.5 60Z" stroke="#1F427D"/>
                                    <path d="M26.7002 34.0498V85.8223H93.4172V34.0498H26.7002ZM33.3641 40.899H86.7521V78.972H33.3641V40.899ZM46.448 45.6582C45.7821 45.6581 45.1227 45.7893 44.5075 46.0441C43.8923 46.2989 43.3333 46.6724 42.8625 47.1433C42.3916 47.6141 42.0181 48.1731 41.7633 48.7883C41.5085 49.4035 41.3774 50.0629 41.3774 50.7288C41.3774 51.3946 41.5086 52.054 41.7634 52.6691C42.0183 53.2843 42.3918 53.8432 42.8626 54.3141C43.3334 54.7849 43.8924 55.1583 44.5076 55.4131C45.1228 55.6679 45.7821 55.7991 46.448 55.7991C47.1139 55.7991 47.7732 55.6679 48.3884 55.4131C49.0036 55.1583 49.5625 54.7849 50.0334 54.3141C50.5042 53.8432 50.8777 53.2843 51.1326 52.6691C51.3874 52.054 51.5186 51.3946 51.5186 50.7288C51.5186 50.0629 51.3875 49.4035 51.1327 48.7883C50.8778 48.1731 50.5043 47.6141 50.0335 47.1433C49.5626 46.6724 49.0037 46.2989 48.3885 46.0441C47.7733 45.7893 47.1139 45.6581 46.448 45.6582ZM66.686 51.3067L58.5911 62.782L54.2324 58.5567L41.5562 71.8111H80.5187L66.686 51.3061V51.3067Z" fill="#1F427D"/>
                                </svg>

                                <span>从相册中选择</span></label>
                            <input id="photo" type="file" accept="image/*" style={{display: "none"}} onChange={(e)=>this.chooseImage(e)}/>
                        </div>
                    )
                }
            </div>
        )
    }
}